import React from 'react';

const WhoAttendedLogos = () => {
  // Array of logo data
  const logos = [
    { id: 1, imgSrc: 'https://s3.amazonaws.com/health-conference/the2conf/attendee-img/Oxford.png' },
    { id: 2, imgSrc: 'https://s3.amazonaws.com/health-conference/the2conf/attendee-img/medtronic.png' },
    { id: 3, imgSrc: 'https://s3.amazonaws.com/health-conference/the2conf/attendee-img/paypal.png' },
    { id: 4, imgSrc: 'https://s3.amazonaws.com/health-conference/the2conf/attendee-img/amezon.png' },
    { id: 5, imgSrc: 'https://s3.amazonaws.com/health-conference/the2conf/attendee-img/auge.png' },
    { id: 6, imgSrc: 'https://s3.amazonaws.com/health-conference/the2conf/attendee-img/fortis.png' },
    { id: 7, imgSrc: 'https://s3.amazonaws.com/health-conference/the2conf/attendee-img/nhs.png' },
    { id: 8, imgSrc: 'https://s3.amazonaws.com/health-conference/the2conf/attendee-img/Kijana.png' },
    { id: 9, imgSrc: 'https://s3.amazonaws.com/health-conference/the2conf/attendee-img/Novo.png' },
    { id: 10, imgSrc: 'https://s3.amazonaws.com/health-conference/the2conf/attendee-img/deloitte.png' }
  ];

  return (
    <>
      <section className="confrence_community text-center who_attend">
        <div className="container">
          <h3><span>Who Attended?</span></h3>
          <p className="mb-5">
            Trailblazers from tech, health, education, and business leadership gathered at our past editions, igniting vibrant discussions on cutting-edge advancements, transformative technologies, and visionary strategies shaping the future of industries.
          </p>
          <ul style={{ display: 'flex', flexWrap: 'wrap', padding: 0, listStyle: 'none' }}>
            {logos.map((logo) => (
              <li key={logo.id} style={{ flex: '0 1 25%', padding: '10px' }}>
               
                 <span>
                 <img src={logo.imgSrc} alt={`logo-${logo.id}`} style={{ maxWidth: '100%' }} />
                 </span>
             
              </li>
            ))}
          </ul>
        </div>
      </section>
    </>
  );
};

export default WhoAttendedLogos;

import React from "react";
const HealthcareGallery = () => {
  return (
    <>
    <section className="image_gallery_section ">
    <div className="container">
      <div className="gallery_heading">
        <h3>Gallery</h3>
        <p>
          Browse through a selection of snapshots from previous healthcare events!
        </p>
      </div>
      <div className="row">
        <div className="col-12 col-sm-6 col-md-6 d-flex image-colmn">
          <div className="row">
            <div className="col-md-6 d-flex image-colmn ">
              <div className="row d-flex image-colmn">
                <div className="col-12 p-0 mb-3  d-flex image-colmn">
                  <div className="past_logos d-flex image-colmn">
                    <img
                      src="https://s3.amazonaws.com/health-conference/the2conf/Healthcare-image/img1.png"
                      alt="img"
                    />
                  </div>
                </div>
                <div className="col-12 p-0 d-flex image-colmn">
                  <div className="past_logos d-flex image-colmn">
                    <img
                      src="https://s3.amazonaws.com/health-conference/the2conf/Healthcare-image/img2.png"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6  d-flex image-colmn">
              <div className="row image-colmn">
                <div className="col-12  d-flex image-colmn">
                  <div className="past_logos d-flex image-colmn">
                    <img
                      src="https://s3.amazonaws.com/health-conference/the2conf/Healthcare-image/img4.png"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-6 d-flex">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 p-0 d-flex mb-3">
              <div className="past_logos d-flex w-100 image-colmn">
                <img
                  src="https://s3.amazonaws.com/health-conference/the2conf/Healthcare-image/img6.png"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 p-0 d-flex image-colmn">
              <div className="past_logos d-flex image-colmn">
                <img
                  src="https://s3.amazonaws.com/health-conference/the2conf/Healthcare-image/img7.png"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6    d-flex p-0">
              <div className="past_logos w-100  d-flex">
                <img
                  src="https://s3.amazonaws.com/health-conference/the2conf/Healthcare-image/img8.png"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12 col-sm-12 col-md-6 d-flex image-colmn">
          <div className="row">
            <div className=" col-sm-6  col-md-6  d-flex image-colmn">
              <div className="past_logos d-flex image-colmn">
                <img
                  src="https://s3.amazonaws.com/health-conference/the2conf/Healthcare-image/img3.png"
                  alt="img"
                />
              </div>
            </div>
            <div className=" col-sm-6  col-md-6  d-flex image-colmn">
              <div className="past_logos d-flex image-colmn">
                <img
                  src="https://s3.amazonaws.com/health-conference/the2conf/Healthcare-image/img5.png"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-6">
          <div className="row">
            <div className="col-12 p-0">
              <div className="past_logos d-flex image-colmn">
                <img
                  src="https://s3.amazonaws.com/health-conference/the2conf/Healthcare-image/img9.png"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
    </>
  );
};

export default HealthcareGallery;

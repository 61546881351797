import React from 'react';
import {SectorsContent} from "../Components/data.js";
import SectorsComp from "../Components/SectorsComp";
import { useEffect } from 'react';
import { Helmet } from "react-helmet";
const AcrossSectors = () => {
  useEffect(() => {
    document.body.className = 'asectors';
    return () => {
      document.body.className = '';
    };
  }, []);
  return (
    <>
    <Helmet>
<meta charset="utf-8" />
<title>Sectors | 2.0 Conferences | World’s Most Impactful Events</title>
<base href="/" />
<meta name="robots" content="index"></meta>
<meta name="description" content="Discover a world of industry-specific, B2B events with 2.0 Conferences, where revolutionary ideas and thought leaders collide for an electrifying experience! " />
<meta property="og:title" content="Sectors | 2.0 Conferences | World’s Most Impactful Events" />

</Helmet>
  <section className=" across_sectors_banner">
    <figure className="gallery_figure">
      <img
        src="https://the2conf.com/static/media/banner_health.406a28d1c6ea68d56606.png"
        alt="img"
        width="100%"
      />
      <figcaption className="gallery_banner_caption">
        <div className="container text-center">
          <div className="across_sectors_content">
            <h1 className="across_sectors_heading">
              <p>Unleashing Innovation</p>
              Across Sectors
            </h1>
            <p>
              The 2.0 Conferences team takes immense pride in curating an
              exceptional lineup of industry-leading conferences across diverse
              sectors. From technology to healthcare, marketing to education,
              each event is thoughtfully designed to connect trailblazers,
              experts, and visionaries from around the globe.
            </p>
          </div>
        </div>
      </figcaption>
    </figure>
  </section>
  {/* =========================== */}
  <section className="gallery_wrappering unleasing_innovation">
    <div className="container">
    {/* =========================== */}
      {SectorsContent.map(SectorsComp) }
   
      {/* =========================== */}
    </div>
  </section>
</>
  )
}

export default AcrossSectors
import React from 'react'
import PastParticipants from '../Components/PastParticipants';
import HealthcareGallery from '../Components/HealthcareGallery';
import { Helmet } from "react-helmet";
import {BannerDataHeth} from "../Components/data.js";
import BannerSection from "../Components/BannerSection";
import {HealthGData} from "../Components/data.js";
import Eleveting  from '../Components/Eleveting';
import {HealthConfAbout} from "../Components/data.js";
import AboutAllConf  from '../Components/AboutAllConf';
export const HealthcarePage = () => {
  return (
    <>
      <Helmet>
<meta charset="utf-8" />
<title>Explore Healthcare Innovations At The 2.0 Conferences</title>
<base href="/" />
<meta name="description" content="Experience firsthand how our healthcare summit — the Health 2.0 Conference — is revolutionizing healthcare and improving patient care, one session at a time! " />
<meta property="og:title" content="Explore Healthcare Innovations At The 2.0 Conferences" />
     </Helmet>
      {/* ====================== */}
    
      <BannerSection bannerContent={BannerDataHeth}/>
      {/* ======================= */}
      <Eleveting SpeakAbout={HealthGData}/>
      {/* =================== */}
      <AboutAllConf ConfAbout={HealthConfAbout}/>
      {/* =================== */}
       {/* =================== */}
       
      <PastParticipants/>
      <HealthcareGallery/>
{/* =================== */}
    </>
  )
}

export default HealthcarePage;
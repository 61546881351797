import React from 'react'
import { Link } from 'react-router-dom';
const SectorsComp = (props) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
  
  <div className="gallery_content">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-4 padding">
            <img src={props.sctorsImg} width="100%" alt="image1"/>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-8  padding">
            <div className="heading_wrappping">
              <h4 className="title_heading">{props.sctorsTitle} </h4>
              <p className="about_heading">
               {props.sctorsContent}
              </p>
              <p className="view_more">

              <Link to={props.sctorsUrl}  onClick={scrollToTop}> View More →</Link>


              </p>
            </div>
          </div>
        </div>
      </div>


  
  )
}

export default SectorsComp
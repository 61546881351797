import React, { useState } from 'react';
import '../speakerform.css';
const SpeakerMailForm = () => {

  const [selectedConference, setSelectedConference] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('');

  const handleConferenceChange = (event) => {
    setSelectedConference(event.target.value);
  };

  const handleTemplateChange = (event) => {
    setSelectedTemplate(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Selected Conference:', selectedConference);
    console.log('Selected Template:', selectedTemplate);
    // You can add your submit logic here
  };

  return (
    <>
    <section className="image_gallery_section ">
        <div className="container">
            <div className="gallery_heading">
                <h3>Speaker Template Form</h3>  
            </div>
            <div className="speaker_form">
                <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="conference">Select Conference:</label>
                    <select id="conference" value={selectedConference} onChange={handleConferenceChange}>
                    <option value="">--Select Conference--</option>
                    <option value="conference1">CXO</option>
                    <option value="conference2">Education</option>
                    <option value="conference3">Health</option>
                    <option value="conference4">Internet</option>
                    <option value="conference5">Marketing</option>
                    </select>
                </div>

                <div>
                    <label htmlFor="template">Select Template:</label>
                    <select id="template" value={selectedTemplate} onChange={handleTemplateChange}>
                    <option value="">--Select Template--</option>
                    <option value="template1">Template 1</option>
                    <option value="template2">Template 2</option>
                    <option value="template3">Template 3</option>
                    </select>
                </div>

                <button className='button_class' type="submit">Submit</button>
                </form>
            </div>
        </div>
    </section>
  
    </>
  );
};

export default SpeakerMailForm;
import React from "react";
import { BrowserRouter as Router, Routes ,Route} from "react-router-dom";
import Home from "../src/Pages/Home";
import Header from "./Components/Header";
import NoteFound from "../src/Pages/NoteFound";
import GalleryPage from "../src/Pages/GalleryPage";
import  HealthcarePage  from "./Pages/HealthcarePage";
import Footer from "./Components/Footer";
import AcrossSectors from "./Pages/AcrossSectors";
import Education from "./Pages/Education";
import Internet from "./Pages/Internet";
import  Fueld  from "./Pages/Fueld";
import Cxo from "./Pages/Cxo";
import EventScam from "./Pages/EventScam";
import BlogsPage from "./Pages/BlogsPage";
import BlogSinglePage from "./Pages/BlogsSinglePage"
import SpeakerMailForm from "./Components/SpeakerMailForm";
import AttendeeReviews from "./Pages/Attendee-Reviews";
import ThankYou from "./Pages/ThankYou";
function App() {
  
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/our-gallery" element={<GalleryPage />} />
          <Route exact path="/Healthcare" element={<HealthcarePage />} />

          <Route exact path="/education" element={<Education />} />
          <Route exact path="/Technology" element={<Internet />} />
          <Route exact path="/fueld" element={<Fueld />} />
          <Route exact path="/business-and-leadership" element={<Cxo />} />

          <Route exact path="/sectors" element={<AcrossSectors />} />
          <Route exact path="/event-scam" element={<EventScam />} />
          <Route exact path="/event-scam" element={<EventScam />} />
          <Route exact path="/blogs" element={<BlogsPage />} />
          <Route exact path="/blog-s-page" element={<BlogSinglePage />} />
          <Route exact path="/speaker-template-form" element={<SpeakerMailForm />}/>
          <Route exact path="/attendee-reviews" element={<AttendeeReviews />} />
          <Route path="*" exact={true} element={<NoteFound />} />
          <Route exact path="/thank-you" element={<ThankYou/>} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;

import React from 'react';

const ReviewCards = () => {
  const reviews = [
    {
      id: 1,
      testimonial: ` “Great, honestly. I've been really impressed with the caliber of the presentations, the keynotes, and the panelists, who made some really good connections.”
`,
      username: 'Luca Ceccarelli',
      role: 'Owner | aiSynergy Leadership Consulting',
      image: 'https://s3.amazonaws.com/health-conference/the2conf/attendee-img/luca.png',
    },
    // Add 9 more review objects here
    {
      id: 2,
      testimonial: ` “Well, it's just the first day, but it's good. It's great, met a lot of awesome people. Everybody's been really friendly and supportive, and it's just been a great experience so far.”
`,
      username: 'Brian J. Bauer',
      role: 'Founder & Executive Director | Abundance Foundation, Inc.',
      image: 'https://s3.amazonaws.com/health-conference/the2conf/attendee-img/brain.png',
    },
    {
        id: 3,
        testimonial: ` “It's been a really great experience, positive overall.”`,
        username: 'Marie Genevieve Pawlak',
        role: 'Founder & President | The Prime Alchemy Group',
        image: 'https://s3.amazonaws.com/health-conference/the2conf/attendee-img/marie.png',
      },
      {
        id: 4,
        testimonial: ` “Just amazing, absolutely amazing. I've met so many new contacts and new friends, and it's just been beyond what I had expected, so I'm looking forward to coming back. It's been fantastic.”
`,
        username: 'Dr. Shana L. Garrett',
        role: 'Dean | Walden University',
        image: 'https://s3.amazonaws.com/health-conference/the2conf/attendee-img/shana.png',
      },
      {
        id: 5,
        testimonial: `“It's been great. I've borated a panel with a bunch of people that I had never met before. We met on a phone call about a week ago and spent some time here at lunch, and it was really nice to learn new PE to meet new people and learn about what they do, and all come together, and all come together it's a great experience.” `,
        username: ' Lawrence Margolis',
        role: 'Founder & CEO | PersonalRX',
        image: 'https://s3.amazonaws.com/health-conference/the2conf/attendee-img/Lawrence.png',
      },
      {
        id: 6,
        testimonial: `“It has been very good because there are so many wonderful speakers and so many people participating that are trying to make that difference in other people's lives as well by imparting their information.”`,
        username: 'Nan McKay',
        role: 'Founder & CEO | Nan McKay',
        image: 'https://s3.amazonaws.com/health-conference/the2conf/attendee-img/nan.png',
      },
      {
        id: 7,
        testimonial: ` “The conference has proven to be an enriching experience, providing infinite opportunities for networking, staying updated about the latest advancements in our field and the education field, and engaging a lot with esteemed colleagues.”`,
        username: 'Dr. Joelle Makoukji Kiklikian',
        role: 'Assistant Professor | Higher Colleges Of Technology',
        image: 'https://s3.amazonaws.com/health-conference/the2conf/attendee-img/joelle.png',
      },
      {
        id: 8,
        testimonial: `“The Health 2.0 Conference is amazing. I met amazing people from Nigeria, from the Netherlands, from the States, India, and all over the world. Very nice networking experience and I made a lot of friends. I've been networking with a lot of people.”`,
        username: ' Yamin Abdel Aziz',
        role: 'Associate Marketing Director | Gilead Sciences',
        image: 'https://s3.amazonaws.com/health-conference/the2conf/attendee-img/yamin.png',
      },
      {
        id: 9,
        testimonial: `“I really had good experience and people gathering a lot from worldwide and experience exchanging you know I meet a lot of people I get you to know to learn from them just for one day and we also waiting for next days a couple of days and really it's a great conference in Dubai.”`,
        username: 'Abdullah Al Dossary',
        role: 'CEO | Crayonz adv.',
        image: 'https://s3.amazonaws.com/health-conference/the2conf/attendee-img/abdullah.png',
      },
      {
        id: 10,
        testimonial: `“I had a great time here. I had the honor and pleasure to meet all these great mindsets of people and educators, where we actually, exchange ideas. I learned a lot from them, a lot of annotative ways of method teaching and also networking. So all of that was a great opportunity, so I'm really grateful.”`,
        username: 'Dr. Wijdan Mubarak Alomaim',
        role: 'Assistant Professor, Researcher & Deputy Head - Radiography And Medical Imaging | Fatima College Of Health Sciences',
        image: 'https://s3.amazonaws.com/health-conference/the2conf/attendee-img/drwijdan.png',
      },
      
  ];

  return (
    <section className="text-center review_cards">
      <div className="container">
        <h3>Find Out What Sets Our Events Apart!</h3>
        <div className="row">
          {reviews.map((review) => (
            <div className="col-md-6 col-12" key={review.id}>
              <div className="testimonial-container">
                <p className="testimonial">{review.testimonial}</p>
                <div className="user">
                  <img
                    src={review.image}
                    alt={review.username}
                    className="user-image"
                  />
                  <div className="user-details">
                    <h4 className="username">{review.username}</h4>
                    <p className="role">{review.role}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
       <p className='testimonyal'>  <i>*Testimonials published with prior permission from past attendees. 
       </i></p>
      </div>
    </section>
  );
};

export default ReviewCards;

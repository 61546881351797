import React from 'react';
const logos = [
  { src: "https://s3.amazonaws.com/health-conference/the2conf/Cxo-image/logo1.png", alt: "Logo 1" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/Cxo-image/logo2.png", alt: "Logo 2" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/Cxo-image/logo3.png", alt: "Logo 3" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/Cxo-image/logo4.png", alt: "Logo 4" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/Cxo-image/logo5.png", alt: "Logo 5" },
  { src: 'https://s3.amazonaws.com/health-conference/the2conf/Cxo-image/logo6.png', alt: "Logo 6" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/Cxo-image/logo7.png", alt: "Logo 7" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/Cxo-image/logo8.png", alt: "Logo 8" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/Cxo-image/logo9.png", alt: "Logo 9" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/Cxo-image/logo10.png", alt: "Logo 10" },
];

const PastParticipantedCxo = () => {
  return (
    <>
      <section className="attendees_sec past_participants">
        <div className="container">
          <h3>
            <span>Our Previous Attendees Have Been From…</span>
          </h3>
          <h4>
            Our prestigious events have gathered CEOs, executives, directors, and leading business
            <br />
            professionals from renowned brands and multinational corporations. Here’s a sneak peek!
          </h4>
        </div>
      </section>
      <section className='healthcare_logos_section'>
        <div className='container'>
          <div className='row justify-content-center'>
            {logos.map((logo, index) => (
              <div className='col-6 col-sm-6 col-md-4 col-lg-2' key={index}>
                <div className='past_logos'>
                  <img src={logo.src} alt={logo.alt} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default PastParticipantedCxo;

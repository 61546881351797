import React from "react";
import { Link, NavLink } from "react-router-dom";

const Header = () => {
 
  return (
    <>
      <header className="main_header">
      <nav className="main_navbar navbar navbar-expand-lg navbar-light sticky-top">
  <div className="container">
    <Link exact to="/" aria-current="page" className="navbar-brand">
      <img src="https://s3.amazonaws.com/health-conference/the2conf/the2-conf-color-logo.svg" alt="logo" className="logo" />
    </Link>
    <button
      className="navbar-toggler w-auto"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span className="navbar-toggler-icon" />
    </button>
    <div
      className="collapse navbar-collapse"
      id="navbarSupportedContent"
    >
    <div className="mx-right main_menu_wrapper navbar-nav">
      <ul className=" main_menu navbar-nav  mb-2 mb-lg-0">
        <li className="nav-item">
          <NavLink to="/our-gallery" className="nav-link ">
            our gallery
          </NavLink>
        </li>
        

        <li className="nav-item">
          <NavLink to="/attendee-reviews" className="nav-link ">
            attendee reviews
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/sectors"
            className="nav-link"
          >
            Sectors
          </NavLink>
        </li>
        
      </ul>
      </div>
    </div>
  </div>
</nav>
      </header>
    </>
  );
};

export default Header;
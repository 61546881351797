import React from 'react'
import { useEffect } from 'react';
import {BlogsbannerData} from "../Components/data.js";
import BannerSection from "../Components/BannerSection";

import { NavLink } from "react-router-dom";
const BlogsPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    useEffect(() => {
        document.body.className = 'blogs';
        return () => {
          document.body.className = '';
        };
      }, []);
  return (
     <>

  <BannerSection bannerContent={BlogsbannerData}/>
  {/* ======= */}
  <section className='blogs_section'>
    <div className='container'>
      <h2>Latest Blog</h2>
      <div className='row'>
          <div className='col-12 col-md-6 col-lg-6 d-flex align-items-center justify-content-center'>
           <div className='blog_content'> 
              <h3>Medical Certification Scam Offenses:
              Identifying Legit Qualifications In Healthcare</h3>
              <p>
              In the fast-paced and ever-evolving world of healthcare, it is important to ensure that healthcare professionals possess legit qualifications. The trust and safety of patients depend on the authenticity of medical certifications. However, the heal.
              </p>
              <NavLink className='read_more' to="/blog-s-page">
                Read More
              </NavLink>
           </div>
          </div>
          <div className='col-12 col-md-6 col-lg-6'>
            <div className='blog_img'>
            <img src="https://s3.amazonaws.com/health-conference/the2conf/blog-page/blog_img.png" alt="blog-img" width="100%"></img>
            </div>
          </div>
          <hr/>
          <div className='col-12 col-md-4 col-lg-4'>
            <div className='blog_img'>
             <img src="https://s3.amazonaws.com/health-conference/the2conf/blog-page/blog_img.png" alt="blog-img" width="100%"></img>
            </div>
            <div className='card_box_content'>
              <h4>
              Legit Ethical Practices In HealthTech: Navigating Innovation And Patient Privacy
              </h4>
              <p>
              In the fast-paced and ever-evolving world of healthcare, it is important to ensure that healthcare professionals possess legit qualifications. The trust and safety of patients depend on the authenticity of medical certifications. However, the heal.
              </p>
              <a className='read_more' href="#">
                Read More
              </a>
            </div>
          </div>
          <div className='col-12 col-md-4 col-lg-4'>
            <div className='blog_img'>
             <img src="https://s3.amazonaws.com/health-conference/the2conf/blog-page/blog_img.png" alt="blog-img" width="100%"></img>
            </div>
            <div className='card_box_content'>
              <h4>
              Legit Ethical Practices In HealthTech: Navigating Innovation And Patient Privacy
              </h4>
              <p>
              In the fast-paced and ever-evolving world of healthcare, it is important to ensure that healthcare professionals possess legit qualifications. The trust and safety of patients depend on the authenticity of medical certifications. However, the heal.
              </p>
              <a className='read_more' href="#">
                Read More
              </a>
            </div>
          </div>
          <div className='col-12 col-md-4 col-lg-4'>
            <div className='blog_img'>
             <img src="https://s3.amazonaws.com/health-conference/the2conf/blog-page/blog_img.png" alt="blog-img" width="100%"></img>
            </div>
            <div className='card_box_content'>
              <h4>
              Legit Ethical Practices In HealthTech: Navigating Innovation And Patient Privacy
              </h4>
              <p>
              In the fast-paced and ever-evolving world of healthcare, it is important to ensure that healthcare professionals possess legit qualifications. The trust and safety of patients depend on the authenticity of medical certifications. However, the heal.
              </p>
              <a className='read_more' href="#">
                Read More
              </a>
            </div>
          </div>
      </div>
    </div>


  </section>

{/* ==== */}

     </>
  )
}

export default BlogsPage;
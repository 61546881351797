import React from 'react'

const AboutAllConf = (props) => {
  return (
   <>
 <section className="healthcare_secondary_section healthcare_about_section">
       
           
       <div className='container-fluid'>
       <div className='row'>
          <div className=' col-12 col-sm-12 col-md-6 '>
        
             <div className='healthcare_secondary_content'>
              <h2>
               <span>{props.ConfAbout[0].title1}</span>
               <span>
               {props.ConfAbout[0].title2}
               </span>
               </h2>
               <p>
               {props.ConfAbout[0].SpeakerAbout}
               </p>
               <p> {props.ConfAbout[0].SpeakerAbout2}</p>

               <p className="view_more"><a href={props.ConfAbout[0].ConfWebsiteUrl} target="_blank" rel="noopener noreferrer">  <button class="btn-alumni">Visit The Website</button> </a></p>
               

              </div>
            
          </div>
          <div className='col-12 col-sm-12 col-md-6 p-0'>
            
             <div className='speaker_image'>
              <img src={props.ConfAbout[0].SpeakerImg}  alt='Speaker_image' width="100%"/>
            </div>
          </div>
        </div>
       </div>
 
   
</section>

   </>
  )
}

export default AboutAllConf
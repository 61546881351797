import React from 'react'

const Card = (props) => {
  return (
    <>
     <div className="col-12 col-sm-6 col-md-3 col-lg-3 mb-5">
      <a href={props.hostedurl}  rel="noopener noreferrer" className='url_box'>
     
        <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip_card_front">
              <div className="card">
                <p>{props.eventName}</p>
                <div className="logo_website">
                  <img
                    src={props.eventImg}
                    className="card-img-top"
                    alt="education"
                    width="100%"
                  />
                </div>
                <div className="card-body">
                  <p className="card-text">
                    {props.evDate} | <br /> {props.eventText}
                  </p>
               
                </div>
              </div>
            </div>
            <div className="flip_card_back">
              <div className="card">
                <div className="card-body">
                  <p className="card-text">
                    {props.cardText}
                  </p>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
     
      
      </a>
      </div>
    </>
  )
}

export default Card

import React from 'react'

const BannerSection = (props) => {

  return (
    <>
    <section className="gallery_banner healthcare_banner">
   
        <figure className="gallery_figure">
          <img src={props.bannerContent[0].bannerimg} alt="img" width="100%" />
          <figcaption className="gallery_banner_caption">
            <div className="container text-center">
              <h1>{props.bannerContent[0].title}</h1>
              <p>{props.bannerContent[0].titlename}</p>
            </div>
          </figcaption>
        </figure>
  
       
      </section>

    </>
  )
}

export default BannerSection
import React from "react";
import OwlCarousel from "react-owl-carousel";


const owl_carousel = {
  margin: 10,
  responsiveClass: true,
  nav: true,
  autoplayHoverPause: true,
  dots: false,
  autoplay: true,
  loop: true,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
      nav: false,
    },
    991: {
      items: 2,
    },
    1024: {
      items: 2,
    },
    1199: {
      items: 3,
    },
  },
};

const testimonials = [
  {
    img: "https://s3.amazonaws.com/health-conference/the2conf/hala.png",
    name: "Dr. Hala Sleiman",
    title: "GM, Near East Region | Merck",
    quote: "What I like the most is the focus on innovation and digitization and how we can make the healthcare systems in a better way. It’s really a great platform.",
  },
  {
    img: "https://s3.amazonaws.com/health-conference/the2conf/peggy.png",
    name: "Peggy Sullivan",
    title: "Founder & CEO | SheCAN!",
    quote: "Within the first two hours of me being here, I made amazing contacts and people that I know I am going to work with and I'm gonna help support and so already, day one... home run!",
  },
  {
    img: "https://s3.amazonaws.com/health-conference/the2conf/Jared_Dalton.jpeg",
    name: "Jared Dalton",
    title: "Owner & CEO | The Jared Dalton Agency LLC",
    quote: "I am thankful to Marketing 2.0 for having me here and it’s like a blessing that I didn’t know I needed.",
  },
  {
    img: "https://s3.amazonaws.com/health-conference/the2conf/Sam_Elshiwick.jpeg",
    name: "Sam Elshiwick",
    title: "President & CEO | DRP Consulting, Inc.",
    quote: "I like it! I mean there’s been a lot of people from all over that are here in very diverse backgrounds that you get to learn. Everybody has a story and that’s very interesting to kind of meet new people and hear what they are going through from a business perspective or startups.",
  },
  {
    img: "https://s3.amazonaws.com/health-conference/the2conf/Benjamin_pulatie.jpeg",
    name: "Benjamin Pulatie",
    title: "CEO | MAD Softwares",
    quote: "When I heard about it, I thought it was a great opportunity to network and continue to build exposure. There’s one thing for certain: if people don’t know about you, they won’t buy from you. So, it’s a great opportunity to connect with other business owners and entrepreneurs.",
  },
  {
    img: "https://s3.amazonaws.com/health-conference/the2conf/Adam_Coughran.jpeg",
    name: "Adam Koffman",
    title: "President | Safekids Ink",
    quote: "The experience has been fantastic, seeing so many different people with different technologies and different curriculums to help and support teachers!",
  },
  {
    img: "https://s3.amazonaws.com/health-conference/the2conf/Esther_Ong_Tengku_Saiful.jpeg",
    name: "Esther Ong",
    title: "Senior Partner | Messrs. Esther Ong Tengku Saiful & Sree",
    quote: "My experience at this conference has been perfect! I can see people from almost around the globe. I met people from Egypt, Africa, Kenya, the US, the UK, the Netherlands, Switzerland, and Asia! So, I'm glad to have this platform to mingle and discuss our legal issues. Thank you so much!",
  },
  {
    img: "https://s3.amazonaws.com/health-conference/the2conf/David_Park.jpeg",
    name: "David Park",
    title: "Founder & CEO | Fintech Automation",
    quote: "It's been amazing. Just meeting a lot of people and getting to know people across the country coming in with different perspectives, venture capital people, to technology people to us!",
  },
  {
    img: "https://s3.amazonaws.com/health-conference/the2conf/Nick_Gaich.png",
    name: "Nick Gaich",
    title: "Owner | Nick Gaich & Associates",
    quote: "This has been wonderful. The diversity, culture exchange, and open communication… these 3 days allowed me to meet individuals and share experiences with them. It was an amazing experience!",
  },
];

const ReviewSlider = () => {
  return (
    <OwlCarousel className="owl-theme home_slider_carousel home_slider_attendee" {...owl_carousel}>
      {testimonials.map((testimonial, index) => (
        <div className="item" key={index}>
          <div className="testimnial-figure item">
            <div className="review_wrapper">
              <img src={testimonial.img} alt={testimonial.name} />
              <div className="review_detail">
                <h4>{testimonial.name}</h4>
                <h5>{testimonial.title}</h5>
              </div>
            </div>
            <p>{testimonial.quote}</p>
          </div>
        </div>
      ))}
    </OwlCarousel>
  );
};

export default ReviewSlider;

import React from 'react';
import PastParticipantedCxo from '../Components/PastParticipatedCxo';
import CxoGallery from '../Components/CxoGallery';
import { Helmet } from "react-helmet";
import {BannerDataCxo} from "../Components/data.js";
import BannerSection from "../Components/BannerSection";
import {CxoGData} from "../Components/data.js";
import Eleveting  from '../Components/Eleveting';
import {CxoConfAbout} from "../Components/data.js";
import AboutAllConf  from '../Components/AboutAllConf';
export const Cxo = () => {
  return (
    <>
  <Helmet>
  <meta charset="utf-8" />
  <title>Encouraging Business Excellence | 2.0 Conferences</title>
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <meta name="theme-color" content="#000000" />
  <link rel="canonical" href="https://the2conf.com/" />
  <base href="/"/>
  <meta name="robots" content="index"></meta>
  <meta name="description" content="Presenting. 2.0 Conferences’ business and leadership conference, CXO 2.0 Conference! Explore how it inspires business leaders towards professional brilliance. " />
  <meta property="og:type" content="Website" />
  <meta property="og:url" content="https://the2conf.com/" />
  <meta property="og:title" content="Encouraging Business Excellence | 2.0 Conferences" />
  <meta property="og:locale" content="en_US" />
  <meta property="og:site_name" content="the2conf" />
</Helmet>
      {/* ====================== */}
    
      <BannerSection bannerContent={BannerDataCxo}/>
      {/* ======================= */}
      <Eleveting SpeakAbout={CxoGData}/>
      {/* =================== */}
      <AboutAllConf ConfAbout={CxoConfAbout}/>
      {/* =================== */}
       {/* =================== */}
       
      <PastParticipantedCxo/>
      <CxoGallery/>
{/* =================== */}
    </>
  )
}

export default Cxo
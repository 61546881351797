import React from 'react'

const Eleveting = (props) => {

  return (
    <>
    <section className="healthcare_secondary_section">
       
       <div className="container">
         <div className='row'>
           <div className=' col-12 col-sm-12 col-md-4 '>
             <div className='speaker_image'>
               <img src={props.SpeakAbout[0].SpeakerImg}  alt='Speaker_image' width="100%"/>
             </div>
           </div>
           <div className='col-12 col-sm-12 col-md-8'>
              <div className='healthcare_secondary_content'>
                <h2>
                <span>{props.SpeakAbout[0].title1}</span>
                <span>
                 {props.SpeakAbout[0].title2} <br></br>
               
                </span>
                </h2>
                <p>
                {props.SpeakAbout[0].SpeakerAbout}
                </p>
                <p>
                 {props.SpeakAbout[0].SpeakerAbout2}
                </p>
              </div>
           </div>
         </div>
       </div>
    
 </section>


    </>
  )
}

export default Eleveting
"use client";
import React, {useState } from "react";
import axios from "axios";
import { css } from "@emotion/react";
import { ScaleLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;
`;
function ContactFrom() {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    designation: "",
    country: "",
    cname: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Set the loading state to true
    try {
      const response = await axios.post(
        "https://api.thescalecreative.com/the2conf-form",
        formData
      );
      console.log(response);
      if (response.status === 200) {
        setTimeout(() => {
          window.location.href = "/thank-you"; // Redirect to the "thank you" page after 3 seconds
        }, 500);
      }
    } catch (error) {
      console.error("Failed to submit form", error);
      // Handle error (e.g., display a user-friendly error message)
    } finally {
      setLoading(false); // Set the loading state to false after request completes
    }
  };

  return (
    <form onSubmit={handleSubmit} className="main_from">
      <div className="row">
        <div className="col-md-4 mt-5">
          <input
            type="text"
            name="name"
            placeholder="Full Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-4 mt-5">
          <input
            type="text"
            name="designation"
            placeholder="Designation"
            value={formData.designation}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-4 mt-5">
          <input
            type="text"
            name="country"
            placeholder="Country Name"
            value={formData.CompanyName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-12 mt-5">
          <input
            type="text"
            name="cname"
            placeholder="Company Name"
            value={formData.CompanyName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-12 mt-5">
          <select
            name="conf_name"
            id="conf_name"
            value={formData.conference}
            onChange={handleChange}
            required
          >
            <option value="">
              Select Conference Name
            </option>
            <option value="Cxo">CXO 2.0</option>
            <option value="Education">EDUCATION 2.0</option>
            <option value="Fueld">FUELD</option>
            <option value="Health">HEALTH 2.0</option>
          </select>
        </div>
        <div className="col-12 mt-5">
          <textarea
            name="message"
            cols="30"
            rows="6"
            placeholder="Write your message here..."
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="col-12 mt-5">
          <button type="submit" className="submit_btn" disabled={loading}>
            {loading ? (
              <ScaleLoader
                css={override}
                size={150}
                color={"#4e94a8"}
                loading={loading}
              />
            ) : (
              <>Submit </>
            )}
          </button>
        </div>
        <p className="by_click">
          By clicking the 'Submit' button, you are granting permission for The
          2.0 Conferences to contact you. This communication may include event
          updates, relevant information, promotional offers, and other content
          related to our conferences.
        </p>
      </div>
    </form>
  );
}

export default ContactFrom;

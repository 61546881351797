import React from 'react'
import InternetGallery from '../Components/InternetGallery';
import PastParticipantedInternet from '../Components/PastParticipatedInternet';
import {BannerDataInternet} from "../Components/data.js";
import BannerSection from "../Components/BannerSection";
import {InternetGData} from "../Components/data.js";
import Eleveting  from '../Components/Eleveting';
import { Helmet } from "react-helmet";
import {InternetConfAbout} from "../Components/data.js";
import AboutAllConf  from '../Components/AboutAllConf';
export const Internet = () => {
  return (
    <>
     <Helmet>
<meta charset="utf-8" />
<title>Discover Tech Trends At The 2.0 Conferences</title>

<meta name="viewport" content="width=device-width, initial-scale=1" />
<meta name="theme-color" content="#000000" />
<link rel="canonical" href="https://the2conf.com/" />
<base href="/" />
<meta name="robots" content="index"></meta>
<meta name="description" content=" Internet 2.0 Conference — our established technology event — unites IT enthusiasts and creates a space for modern tech innovation.  " />
<meta property="og:type" content="Website" />
<meta property="og:url" content="https://the2conf.com/" />
<meta property="og:title" content="Discover Tech Trends At The 2.0 Conferences" />
<meta property="og:locale" content="en_US" />
<meta property="og:site_name" content="the2conf" />
</Helmet>
      {/* ====================== */}
     
      <BannerSection bannerContent={BannerDataInternet}/>
      {/* ======================= */}
      <Eleveting SpeakAbout={InternetGData}/>
      {/* =================== */}
      <AboutAllConf ConfAbout={InternetConfAbout}/>
      {/* =================== */}
       {/* =================== */}
       
      <PastParticipantedInternet/>
      <InternetGallery/>
{/* =================== */}
    </>
  )
}


export default Internet;
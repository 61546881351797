import React from 'react'
import Gallary from '../Components/Gallary';
import { useEffect } from 'react';
import { GallaryContent } from '../Components/data';
import { Helmet } from "react-helmet";
const GalleryPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
useEffect(() => {
    document.body.className = 'gallery_gallery';
    return () => {
      document.body.className = '';
    };
  }, []);
  return (
    <>
<Helmet>
<meta charset="utf-8" />
<title>Event Gallery | The 2.0 Conference"</title>
<meta name="viewport" content="width=device-width, initial-scale=1" />
<base href="/" />
<meta name="description" content=" Peek into memories of our successful past events capturing the essence of healthcare, tech, education and more. Dive into the 2.0 Conference’s event gallery! " />
<meta property="og:title" content="Event Gallery | The 2.0 Conference" />

</Helmet>
 <section className="gallery_banner">
        <figure className="gallery_figure">
          <img src="https://s3.amazonaws.com/health-conference/the2conf/gallery_banner.jpg" alt="img" width="100%" />
          <figcaption className="gallery_banner_caption">
            <div className="container text-center">
              <h1>Events Gallery</h1>
              <p>Check out photographs from our events around the world! </p>
            </div>
          </figcaption>
        </figure>
      </section>
      <section className="confrence_community text-center">
        <div className="container">
          <h3>Snapshots Of Memories 
<span> From Our Past Events</span></h3>
         
          <p>Welcome to our Events Gallery page! Here, you'll find a curated collection of captivating moments from our diverse events. Each memory here captures the essence of the experiences shared and the stories created. Browse through to relive the highlights, feel the emotions, and immerse yourself in the ambience of these unforgettable occasions.  </p><br></br>

          <p>Dive in and let the visuals transport you back to those incredible moments!</p>
        </div>
      </section>
     {GallaryContent.map(Gallary)}



    </>
  )
}

export default GalleryPage
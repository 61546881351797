import React from "react";
const Video = () => {
  return (
    <>
  
      
     {/* <video className="vedio_banner"  src={VedioBanner}   autoPlay loop muted  style={{width: "100%"}}></video> */}
  
     <video className="vedio_banner"  width="100%" height="100%" autoPlay loop muted>
         <source src="https://s3.amazonaws.com/health-conference/the2conf/2conf.mp4" type="video/mp4"/>
     </video>

    </>
  );
};

export default Video;

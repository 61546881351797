import React from "react";
// import jQuery from "jquery";
// import $ from "jquery";
import OwlCarousel from 'react-owl-carousel';
const images = [
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-1.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-2.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-3.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-4.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-5.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-6.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-7.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-8.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-9.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-10.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-11.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-12.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-13.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-14.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-15.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-16.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-17.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-18.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-19.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-20.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-21.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-22.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-23.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-24.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-25.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-26.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-27.png",
  "https://s3.amazonaws.com/health-conference/the2conf/Untitled-28.png",
 
];

const owl_logo_Slider = {
  margin: 10,
  responsiveClass: true,
  nav: true,
  autoplayHoverPause:true,
  dots: false,
  autoplay: true,
  loop:true,
  responsive: {
      0: {
          items: 1,
      },
      575 :{
        items: 2,
      },
      767:{
       items : 3,
      },
      991: {
         items: 4,
      },
      1024: {
          items: 4,
      },
     
      1199: {
          items: 5,

      }
  },
};
const HomeSlider = () => {
  return (
    <>
      <section className="home_slider">
        <div className="container">
          <OwlCarousel
            className="owl-theme home_slider_carousel "
            {...owl_logo_Slider}
          >
              {images.map((src, index) => (
            <div className="item" key={index}>
              <img src={src} alt={`Slide ${index + 1}`} />
            </div>
          ))}
          </OwlCarousel>
        </div>
      </section>
    </>
  );
};

export default HomeSlider;

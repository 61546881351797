import React from 'react'
import { Helmet } from "react-helmet";
import PastParticipantedMarketing from '../Components/PastParticipatedMareketing.jsx';
import MarketingGallery from '../Components/MarketingGallery.jsx';
import {BannerDataMark} from "../Components/data.js";
import BannerSection from "../Components/BannerSection.jsx";
import {MarketingGData} from "../Components/data.js";
import Eleveting  from '../Components/Eleveting.jsx';
import {MarketingConfAbout} from "../Components/data.js";
import AboutAllConf  from '../Components/AboutAllConf.jsx';

export const Fueld = () => {
  return (
    <>
<Helmet>
<meta charset="utf-8" />
<title> Marketing Tech Conference | The 2.0 Conferences</title>
<base href="/" />
<meta name="description" content=" This page explores how The 2.0 Conferences’ marketing tech conference will talk about the trends that are redefining the marketing and tech industries.  " />
<meta property="og:title" content="Marketing Tech Conference | The 2.0 Conferences" />

</Helmet>

      {/* ====================== */}
      <BannerSection bannerContent={BannerDataMark}/>
      {/* ======================= */}
      <Eleveting SpeakAbout={MarketingGData}/>
      {/* =================== */}
      <AboutAllConf ConfAbout={MarketingConfAbout}/>
       {/* =================== */}
       
      <PastParticipantedMarketing/>
      <MarketingGallery/>
{/* =================== */}
    </>
  )
}



export default Fueld;
import React from 'react'
import PastParticipantedEducation from '../Components/PastParticipatedEducation';
import EducationGallery from '../Components/EducationGallery';
import { Helmet } from "react-helmet";
import {BannerDataEdu} from "../Components/data.js";
import BannerSection from "../Components/BannerSection";
import {EducationGData} from "../Components/data.js";
import Eleveting  from '../Components/Eleveting';
import {EducationConfAbout} from "../Components/data.js";
import AboutAllConf  from '../Components/AboutAllConf';
export const Education = () => {
  return (
    <>
 <Helmet>
<meta charset="utf-8" />
<title>On A Mission To Transform Education — 2.0 Conferences</title>
<base href="/" />
<meta name="description" content="Introducing Education 2.0 Conference, presented by 2.0 Conferences! Immerse yourself in powerful discussions around improving student outcomes while networking with your peers. " />
<meta property="og:title" content="On A Mission To Transform Education — 2.0 Conferences" />

</Helmet>
   
     {/* ======================= */}
      <BannerSection bannerContent={BannerDataEdu}/>
      {/* ======================= */}
      <Eleveting SpeakAbout={EducationGData}/>
    
      {/* =================== */}
      <AboutAllConf ConfAbout={EducationConfAbout}/>
      {/* =================== */}
       {/* =================== */}
       
      <PastParticipantedEducation/>
      <EducationGallery/>
{/* =================== */}
    </>
  )
}

export default Education;
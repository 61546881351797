import React from 'react';
const imageList = [
  { src: "https://s3.amazonaws.com/health-conference/the2conf/dr-reddy,s.png", alt: "Dr. Reddy's" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/Untitled-12.png", alt: "Slide 12" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/Untitled-21.png", alt: "Slide 21" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/iqgen.png", alt: "IQGen" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/king-faisal.png", alt: "King Faisal" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/Untitled-4.png", alt: "Slide 4" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/Untitled-19.png", alt: "Slide 19" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/Untitled-8.png", alt: "Slide 8" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/johanson-johanson.png", alt: "Johanson Johanson" },
  { src: "https://s3.amazonaws.com/health-conference/the2conf/Mayo-clinic-logo.png", alt: "Mayo Clinic" },
];

const PastParticipants = () => {
  return (
    <>
       <section class="attendees_sec past_participants">
        <div class="container">
          <h3>
             <span>Our Previous Attendees Have Been From…</span>
          </h3>
          <h4>
             Our distinguished attendees include doctors, executives, and professionals from reputed hospitals and
            <br />
            multinational corporations known for their innovative practices in the healthcare sector.
          </h4>
        </div>
      
      </section>
      {/* Your additional sections or content can go here */}

      <section className='healthcare_logos_section'>
        <div className='container'>
          <div className='row justify-content-center'>
            {imageList.map((image, index) => (
              <div key={index} className='col-6 col-sm-6 col-md-4 col-lg-2'>
                <div className='past_logos'>
                  <img src={image.src} alt={image.alt} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Additional sections or content can go here */}
    </>
  )
}

export default PastParticipants;
